<template>
  <div class="view-home">
    <div class="desktop-view">
      <div class="container">
        <div class="tabs-list">
          <div class="tab-item" :class="{ 'disabled-tab': currentTab !== 'about-test' && currentTab !== 'about-test-2' }" v-html="$t('nav_1')">

          </div>
          <svg width="26" height="3" viewBox="0 0 26 3" fill="none" xmlns="http://www.w3.org/2000/svg"
               v-if="currentTab == 'about-test'">
            <rect x="0.200195" y="0.5" width="25" height="2" fill="#2563EB"/>
          </svg>
          <svg width="26" height="3" viewBox="0 0 26 3" fill="none" xmlns="http://www.w3.org/2000/svg"
               v-if="currentTab != 'about-test'">
            <rect x="0.599609" y="0.5" width="25" height="2" fill="#E5E7EB"/>
          </svg>
          <div class="tab-item" :class="{ 'disabled-tab' : currentTab != 'access-microphone' }" v-html="$t('nav_2')">

          </div>
          <svg width="26" height="3" viewBox="0 0 26 3" fill="none" xmlns="http://www.w3.org/2000/svg"
               v-if="currentTab == 'access-microphone'">
            <rect x="0.200195" y="0.5" width="25" height="2" fill="#2563EB"/>
          </svg>
          <svg width="26" height="3" viewBox="0 0 26 3" fill="none" xmlns="http://www.w3.org/2000/svg"
               v-if="currentTab != 'access-microphone'">
            <rect x="0.599609" y="0.5" width="25" height="2" fill="#E5E7EB"/>
          </svg>

<!--          <div class="tab-item" :class="{ 'disabled-tab' : currentTab != 'final-check' }">-->
<!--            ФИНАЛЬНАЯ<br>-->
<!--            ПРОВЕРКА-->
<!--          </div>-->
<!--          <svg width="26" height="3" viewBox="0 0 26 3" fill="none" xmlns="http://www.w3.org/2000/svg"-->
<!--               v-if="currentTab == 'final-check'">-->
<!--            <rect x="0.200195" y="0.5" width="25" height="2" fill="#2563EB"/>-->
<!--          </svg>-->
<!--          <svg width="26" height="3" viewBox="0 0 26 3" fill="none" xmlns="http://www.w3.org/2000/svg"-->
<!--               v-if="currentTab != 'final-check'">-->
<!--            <rect x="0.599609" y="0.5" width="25" height="2" fill="#E5E7EB"/>-->
<!--          </svg>-->
          <div class="tab-item" :class="{ 'disabled-tab' : currentTab != 'start-test' }" v-html="$t('nav_3')">

          </div>
        </div>

        <section v-if="currentTab == 'about-test'" class="about-test">
          <div class="desc-test">
            <div class="d-flex">
              <h2>
                {{ $t('page_rules_1')}}
              </h2>
              <div class="tooltip-container">
                <img
                    width="24"
                    height="24"
                    src="/images/warning-triangle.svg"
                    alt="Warning"
                    @mouseenter="showTooltip = true"
                    @mouseleave="showTooltip = false"
                    class="triangle-icon"
                />
                <div v-if="showTooltip" class="tooltip-block">
                  <p>
                    {{ $t('rules_1')}}
                  </p>
                  <p>
                    ⚠️ {{ $t('rules_2')}}
                  </p>
                  <p>
                    ⚠️ {{ $t('rules_3')}}
                  </p>
                  <p>
                    ⚠️ {{ $t('rules_4')}}
                  </p>
                  <p>
                    ⚠️ {{ $t('rules_5')}}
                  </p>
                </div>
              </div>
            </div>
            <div class="test-info">
              <div class="rules-container">
                <div class="rules-left-block">
                  <iframe class="rules-youtube-link" v-if="$i18n.locale == 'ru'"
                          src="https://www.youtube.com/embed/jJ0d0rigmvA" allowfullscreen></iframe>
                  <iframe class="rules-youtube-link" v-if="$i18n.locale == 'kz'"
                          src="https://www.youtube.com/embed/02jMulCdlLM" allowfullscreen></iframe>
                </div>
                <div class="rules-right-block">
                  <img src="/images/proctoring-img.jpg" width="55%"  alt=""/>
                  <div class="rules-list-text">
                    <span>
                      {{ $t('page_rules_2')}}
                    </span>
                    <span>
                      {{ $t('page_rules_3')}}
                    </span>
                    <span>
                      {{ $t('page_rules_4')}}
                    </span>
                    <span>
                      {{ $t('page_rules_5')}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="separate-line">

            </div>
            <div class="rules-btns-block">
              <a class="test-prev-btn" href="https://1000bala.elumiti.kz/testing">
                {{ $t('about.back-button')}}
              </a>

              <button class="test-next-btn" @click="nextTab(tabs.accessMicrophone)"
                      :class="{ disabled: !isButtonActive  }" :disabled="!isButtonActive "
              >
                {{ $t('next_1')}} <span v-if="timer > 0">({{ timer }})</span>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.675 21.9508L22.1226 12.5008L12.6726 3.05078M1.875 12.5008H22.125H1.875Z" stroke="#FBFBFD"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>

          </div>
        </section>

<!--        <section v-if="currentTab=='about-test-2'" class="about-test">-->
<!--          <div class="desc-test">-->
<!--&lt;!&ndash;            <div class="d-flex">&ndash;&gt;-->
<!--&lt;!&ndash;              <h2>&ndash;&gt;-->
<!--&lt;!&ndash;                О тесте&ndash;&gt;-->
<!--&lt;!&ndash;              </h2>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="tooltip-container">&ndash;&gt;-->
<!--&lt;!&ndash;                <img&ndash;&gt;-->
<!--&lt;!&ndash;                    width="24"&ndash;&gt;-->
<!--&lt;!&ndash;                    height="24"&ndash;&gt;-->
<!--&lt;!&ndash;                    src="/images/warning-triangle.svg"&ndash;&gt;-->
<!--&lt;!&ndash;                    alt="Warning"&ndash;&gt;-->
<!--&lt;!&ndash;                    @mouseenter="showTooltip = true"&ndash;&gt;-->
<!--&lt;!&ndash;                    @mouseleave="showTooltip = false"&ndash;&gt;-->
<!--&lt;!&ndash;                    class="triangle-icon"&ndash;&gt;-->
<!--&lt;!&ndash;                />&ndash;&gt;-->
<!--&lt;!&ndash;                <div v-if="showTooltip" class="tooltip-block microphone">&ndash;&gt;-->
<!--&lt;!&ndash;                  <p>&ndash;&gt;-->
<!--&lt;!&ndash;                    ПОДРОБНЕЕ О ДОСТУПЕ К МИКРОФОНУ:<br>&ndash;&gt;-->
<!--&lt;!&ndash;                    Мы используем микрофон для фиксации нарушении, если вы или кто-то разговаривает.<br>&ndash;&gt;-->
<!--&lt;!&ndash;                    Потенциальные нарушения записываются и будут пересмотрены.&ndash;&gt;-->
<!--&lt;!&ndash;                  </p>&ndash;&gt;-->

<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--            <div class="access-microphone-rules">-->
<!--              <div class="rules-left rules-left-block">-->

<!--                <div class="d-flex">-->
<!--                  <h2 class="about-test-title-2">-->
<!--                    О тесте-->
<!--                  </h2>-->
<!--                  <div class="tooltip-container">-->
<!--                    <img-->
<!--                        width="24"-->
<!--                        height="24"-->
<!--                        src="/images/warning-triangle.svg"-->
<!--                        alt="Warning"-->
<!--                        @mouseenter="showTooltip = true"-->
<!--                        @mouseleave="showTooltip = false"-->
<!--                        class="triangle-icon"-->
<!--                    />-->
<!--                    <div v-if="showTooltip" class="tooltip-block microphone">-->
<!--                      <p>-->
<!--                        Описание о тесте-->
<!--                      </p>-->

<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="rules-left-list">-->
<!--                  <h2>-->
<!--                    ПРАВИЛА-->
<!--                  </h2>-->
<!--                  <p>-->
<!--                    Сидите в тихом, хорошо освещённом месте.-->
<!--                  </p>-->
<!--                  <p>-->
<!--                    На столе только необходимые для экзамена вещи.-->
<!--                  </p>-->
<!--                  <p>-->
<!--                    Камера и микрофон должны работать.-->
<!--                  </p>-->
<!--                  <p>-->
<!--                    Нельзя пользоваться телефоном, шпаргалками или разговаривать с кем-либо-->
<!--                  </p>-->

<!--                </div>-->
<!--              </div>-->
<!--              <div class="rules-right">-->

<!--                <img-->
<!--                    width="393"-->
<!--                    height="240"-->
<!--                    src="/images/about-test-image.png"-->

<!--                />-->

<!--                <div class="about-proctoring">-->
<!--                  В олимпиаде используется "Прокторинг" обеспечения для академической честности участников.-->
<!--                </div>-->

<!--              </div>-->
<!--            </div>-->
<!--            <div class="border-line">-->

<!--            </div>-->

<!--            <div class="rules-btns-block">-->
<!--              <button class="test-prev-btn" @click="nextTab(tabs.aboutTest)">-->
<!--                {{ $t('about.back-button')}}-->
<!--              </button>-->

<!--              <button class="test-next-btn" @click="nextTab(tabs.accessMicrophone)">-->
<!--                {{ $t('next_1')}}-->
<!--                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M12.675 21.9508L22.1226 12.5008L12.6726 3.05078M1.875 12.5008H22.125H1.875Z" stroke="#FBFBFD"-->
<!--                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                </svg>-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </section>-->

        <section v-if="currentTab=='access-microphone'" class="access-microphone">
          <div class="desc-test">
            <div class="d-flex">
              <h2>
                {{ $t('nav_4')}}
              </h2>
              <div class="tooltip-container">
                <img
                    width="24"
                    height="24"
                    src="/images/warning-triangle.svg"
                    alt="Warning"
                    @mouseenter="showTooltip = true"
                    @mouseleave="showTooltip = false"
                    class="triangle-icon"
                />
                <div v-if="showTooltip" class="tooltip-block microphone">
                  <p>
                    {{ $t('modal_6')}}:<br>
                    {{ $t('modal_7')}}<br>
                    {{ $t('modal_8')}}
                  </p>

                </div>
              </div>
            </div>
            <div class="access-microphone-rules">
              <div class="rules-left">
<!--                <div class="rules-left-list">-->
<!--                  <h2>-->
<!--                    НЕ РАЗГОВАРИВАТЬ!-->
<!--                  </h2>-->
<!--                  <p>-->
<!--                    Вы должны быть <span> один </span> в тихом месте</p>-->
<!--                  <p>-->
<!--                    Во время тестирования вам <span>нельзя разговаривать</span></p>-->
<!--                  <p>-->
<!--                    Вам <span>нельзя</span> читать вопрос вслух</p>-->
<!--                </div>-->
                <div class="rules-left-list">
                  <div class="warning-text">
                      <span>
                        {{$t('page_rules_6')}}
                      </span>
                    <span>
                        {{$t('page_rules_7')}}
                      </span>
                  </div>
                  <div class="sound-display-block">
                    <div class="soundbar-block">
                      <div v-if="!hasMicrophoneAccess" class="no-sound">{{$t('page_rules_8')}}</div>
                      <div v-else v-html="noiseMessage">{{ noiseMessage }}</div>
                      <div class="soundbar" :class="{ disabled: !hasMicrophoneAccess, 'soundbar-active' : hasMicrophoneAccess }">
                        <div
                            v-for="(bar, index) in bars"
                            :key="index"
                            class="bar"
                            :style="{ height: bar.height + '%', backgroundColor: bar.color }"
                        ></div>
                      </div>
                    </div>
                  </div>

<!--                  <button class="microphone-allow-access" @click="requestAccess" v-if="!hasMicrophoneAccess">-->
<!--                    Разрешить доступ-->
<!--                  </button>-->
                  <h2 class="microphone-success-title" v-if="hasMicrophoneAccess">
                    {{$t('page_rules_19')}}
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.5" y="0.6875" width="15" height="15" rx="7.5" fill="#039855"/>
                      <rect x="0.5" y="0.6875" width="15" height="15" rx="7.5" stroke="#039855"/>
                      <path d="M11.3337 5.6875L6.75033 10.2708L4.66699 8.1875" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                  </h2>
                  <div class="noise-levels">
                    <div class="noise-level">
                      <img src="/images/high-noise.svg" width="69" height="54" alt="high-noise"/>
                      {{$t('page_rules_13')}}
                    </div>
                    <div class="noise-level">
                      <img src="/images/middle-noise.svg" width="69" height="54" alt="middle-noise"/>
                      {{$t('page_rules_14')}}
                    </div>
                    <div class="noise-level">
                      <img src="/images/low-noise.svg" width="69" height="54" alt="low-noise"/>
                      {{$t('page_rules_15')}}
                    </div>
                  </div>
                </div>
<!--                <div class="noise-levels">-->
<!--                  <div class="noise-level">-->
<!--                    <img src="/images/high-noise.svg" width="69" height="54" alt="high-noise"/>-->
<!--                    Высокий шум-->
<!--                  </div>-->
<!--                  <div class="noise-level">-->
<!--                    <img src="/images/middle-noise.svg" width="69" height="54" alt="middle-noise"/>-->
<!--                    Средний шум-->
<!--                  </div>-->
<!--                  <div class="noise-level">-->
<!--                    <img src="/images/low-noise.svg" width="69" height="54" alt="low-noise"/>-->
<!--                    Низкий шум-->
<!--                  </div>-->
<!--                </div>-->
              </div>
              <div class="rules-right-microphone">
<!--                <div class="sound-display-block">-->
<!--                  <img src="/images/low-sound-image.png" width="166" height="164" alt=""/>-->
<!--                  <div class="soundbar-block">-->
<!--                    <p v-if="!hasMicrophoneAccess" class="no-sound">Звука нет</p>-->
<!--                    <p v-else v-html="noiseMessage">{{ noiseMessage }}</p>-->
<!--                    <div class="soundbar" :class="{ disabled: !hasMicrophoneAccess, 'soundbar-active' : hasMicrophoneAccess }">-->
<!--                      <div-->
<!--                          v-for="(bar, index) in bars"-->
<!--                          :key="index"-->
<!--                          class="bar"-->
<!--                          :style="{ height: bar.height + '%', backgroundColor: bar.color }"-->
<!--                      ></div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <button class="microphone-allow-access" @click="requestAccess" v-if="!hasMicrophoneAccess">-->
<!--                  Разрешить доступ-->
<!--                </button>-->
<!--                <h2 class="microphone-success-title" v-if="hasMicrophoneAccess">-->
<!--                  Доступ к микрофону предоставлен-->
<!--                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <rect x="0.5" y="0.6875" width="15" height="15" rx="7.5" fill="#039855"/>-->
<!--                    <rect x="0.5" y="0.6875" width="15" height="15" rx="7.5" stroke="#039855"/>-->
<!--                    <path d="M11.3337 5.6875L6.75033 10.2708L4.66699 8.1875" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  </svg>-->

<!--                </h2>-->
                <div class="warning-text">
                  <span>
                    {{$t('page_rules_11')}}
                  </span>
                  <span>
                    {{$t('page_rules_12')}}
                  </span>
                </div>

                <div class="rules-right-microphone rules-right-camera">

                  <div class="camera-display-block">
                    <div class="position-relative">
                      <video width="450" height="264" v-if="hasAccessCamera" ref="videoElement" autoplay playsinline muted disablePictureInPicture class="camera-video-block"></video>
                      <canvas width="450" height="264" ref="canvas1"></canvas>
                      <div class="loader-face-block" v-if="isLoadingFace">
                        <div class="find-face-text">{{ $t('find_face')}}</div>
                        <div class="spinner"></div>
                      </div>
                      <img v-if="!hasAccessCamera" width="450" height="264" alt="" src="/images/no-camera.png"/>
                    </div>
                  </div>

                  <div class="video-access-btn">

<!--                    <button class="microphone-allow-access" @click="requestAccessCamera" v-if="!hasAccessCamera">-->
<!--                      Разрешить доступ к камере-->
<!--                    </button>-->
                    <div class="camera-access-granted" v-if="hasAccessCamera">
                      {{$t('page_rules_20')}}
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#039855"/>
                        <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#039855"/>
                        <path d="M11.3337 5.5L6.75033 10.0833L4.66699 8" stroke="white" stroke-width="1.66667"
                              stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div class="d-flex justify-content-center">
              <button class="microphone-allow-access-new" @click="requestAccessCameraMirco" v-if="!hasMicrophoneAccess || !hasAccessCamera">
                {{$t('page_rules_16')}}
              </button>
            </div>
            <div class="border-line">

            </div>
            <h2 class="microphone-warning-subtitle" v-if="!hasMicrophoneAccess || !hasAccessCamera">
              {{$t('page_rules_17')}}
            </h2>
            <h2 class="microphone-warning-subtitle" v-if="this.noiseLevel > this.lowNoiseLevel" v-html="noiseMessage">

            </h2>

            <div class="rules-btns-block">
              <button class="test-prev-btn" @click="nextTab(tabs.aboutTest)">
                {{ $t('about.back-button')}}
              </button>

              <button class="test-next-btn" :disabled="!hasMicrophoneAccess || noiseLevel > this.lowNoiseLevel || !hasAccessCamera || !isFaceDetected"
                      :class="{ disabled: !hasMicrophoneAccess || noiseLevel > this.lowNoiseLevel || !hasAccessCamera || !isFaceDetected}" @click="nextTab(tabs.startTest)">
                {{$t('page_rules_18')}}
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.675 21.9508L22.1226 12.5008L12.6726 3.05078M1.875 12.5008H22.125H1.875Z" stroke="#FBFBFD"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
        </section>

<!--        <section v-if="currentTab=='access-camera'" class="access-microphone access-camera">-->
<!--          <div class="desc-test">-->
<!--            <div class="d-flex">-->
<!--              <h2>-->
<!--                Доступ к камере-->
<!--              </h2>-->
<!--              <div class="tooltip-container">-->
<!--                <img-->
<!--                    width="24"-->
<!--                    height="24"-->
<!--                    src="/images/warning-triangle.svg"-->
<!--                    alt="Warning"-->
<!--                    @mouseenter="showTooltip = true"-->
<!--                    @mouseleave="showTooltip = false"-->
<!--                    class="triangle-icon"-->
<!--                />-->
<!--                <div v-if="showTooltip" class="tooltip-block microphone">-->
<!--                  <p>-->
<!--                    ПОДРОБНЕЕ О ДОСТУПЕ К КАМЕРЕ:<br>-->
<!--                    Мы используем камеру чтобы убедиться, что вы следуете правилам олимпиады. Вы должны быть о ун во-->
<!--                    время-->
<!--                    сдачи тестирования. Ваше лицо должно быть четко - видно на камере. Система прокторинга будет-->
<!--                    проверять-->
<!--                    ваши фотографии..-->
<!--                  </p>-->

<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="access-microphone-rules">-->
<!--              <div class="rules-left">-->
<!--                <div class="rules-left-list">-->
<!--                  <h2>-->
<!--                    КАМЕРА ЗАПИСЫВАЕТ!-->
<!--                  </h2>-->
<!--                  <div>-->
<!--                    Во время тестирования камера будет делать фотографии для проверки того, что вы правильно сдаете-->
<!--                    тест.-->
<!--                  </div>-->
<!--                  <p>-->
<!--                    Ваше лицо должно быть <span>всегда</span> в кадре-->
<!--                  </p>-->
<!--                  <p>-->
<!--                    Вы <span>самостоятельно</span> должны сдавать тест, <span>без посторонних</span>-->
<!--                  </p>-->
<!--                  <p>-->
<!--                    В помещении должно быть <span>светло</span> и ваше лицо должно быть <span>хорошо освещено</span>-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="rules-right-microphone rules-right-camera">-->

<!--                <div class="camera-display-block">-->
<!--                  <img src="/images/access-camera-image.png" width="180" height="180" alt=""/>-->

<!--                  <div>-->
<!--                    <video v-if="hasAccessCamera" ref="videoElement" autoplay playsinline muted disablePictureInPicture class="camera-video-block"></video>-->
<!--                    <img v-else width="203" height="157" alt="" src="/images/no-camera.png"/>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div class="video-access-btn">-->

<!--                  <button class="microphone-allow-access" @click="requestAccessCamera" v-if="!hasAccessCamera">-->
<!--                    Разрешить доступ к камере-->
<!--                  </button>-->
<!--                  <div class="camera-access-granted" v-if="hasAccessCamera">-->
<!--                    Доступ к камере предоставлен-->
<!--                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#039855"/>-->
<!--                      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#039855"/>-->
<!--                      <path d="M11.3337 5.5L6.75033 10.0833L4.66699 8" stroke="white" stroke-width="1.66667"-->
<!--                            stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                    </svg>-->
<!--                  </div>-->
<!--                </div>-->

<!--              </div>-->
<!--            </div>-->
<!--            <div class="border-line">-->

<!--            </div>-->
<!--            <h2 class="microphone-warning-subtitle" v-if="!hasAccessCamera">-->
<!--              Для продолжения необходимо предоставить доступ к камере!-->
<!--            </h2>-->

<!--            <div class="rules-btns-block">-->
<!--              <button class="test-prev-btn" @click="nextTab(tabs.accessMicrophone)">-->
<!--                {{ $t('about.back-button')}}-->
<!--              </button>-->

<!--              <button class="test-next-btn" :disabled="!hasAccessCamera" :class="{ disabled: !hasAccessCamera }" @click="nextTab(tabs.finalCheck)">-->
<!--                {{$t('page_rules_18')}}-->
<!--                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M12.675 21.9508L22.1226 12.5008L12.6726 3.05078M1.875 12.5008H22.125H1.875Z" stroke="#FBFBFD"-->
<!--                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                </svg>-->
<!--              </button>-->
<!--            </div>-->

<!--&lt;!&ndash;            <button class="test-next-btn" :disabled="!hasAccessCamera" :class="{ disabled: !hasAccessCamera }"&ndash;&gt;-->
<!--&lt;!&ndash;                    @click="nextTab(tabs.finalCheck)">&ndash;&gt;-->
<!--&lt;!&ndash;              {{$t('page_rules_18')}}&ndash;&gt;-->
<!--&lt;!&ndash;              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">&ndash;&gt;-->
<!--&lt;!&ndash;                <path d="M12.675 21.9508L22.1226 12.5008L12.6726 3.05078M1.875 12.5008H22.125H1.875Z" stroke="#FBFBFD"&ndash;&gt;-->
<!--&lt;!&ndash;                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>&ndash;&gt;-->
<!--&lt;!&ndash;              </svg>&ndash;&gt;-->
<!--&lt;!&ndash;            </button>&ndash;&gt;-->
<!--          </div>-->
<!--        </section>-->

<!--        <section v-if="currentTab=='final-check'" class="profile-photo">-->

<!--          <div class="main-block">-->
<!--            <div class="main-right">-->
<!--              <div class="main-right-list">-->
<!--                <h2>-->
<!--                  Основные требования-->
<!--                </h2>-->
<!--                <div>-->
<!--                  Направьте веб-камеру так, чтобы ваше лицо оказалось в центре овала. Убедитесь, что ваше лицо хорошо-->
<!--                  видно.-->
<!--                </div>-->
<!--                <p>-->
<!--                  Лицо должно быть открыто и находиться в кадре;-->
<!--                </p>-->
<!--                <p>-->
<!--                  Не должно быть лишних предметов (маска, очки и т.п.);-->
<!--                </p>-->
<!--                <p>-->
<!--                  На фоне не должны быть другие люди и посторонние предметы;-->
<!--                </p>-->
<!--                <p>-->
<!--                  В помещении должно быть светло и ваше лицо должно быть хорошо освещено-->
<!--                </p>-->

<!--                <div class="photo-warning-title" v-if="!requirements">-->
<!--                  <p>Сделайте фото заново!</p>-->
<!--                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path-->
<!--                        d="M21.05 18.7069L13.0611 3.87094C12.4948 2.81906 10.9864 2.81906 10.4197 3.87094L2.43122 18.7069C2.30827 18.9352 2.24663 19.1916 2.25231 19.4508C2.258 19.7101 2.33081 19.9635 2.46365 20.1863C2.59648 20.409 2.7848 20.5935 3.01022 20.7217C3.23564 20.85 3.49046 20.9176 3.74981 20.918H19.729C19.9886 20.918 20.2437 20.8507 20.4694 20.7226C20.6952 20.5945 20.8838 20.4101 21.0169 20.1873C21.15 19.9644 21.2231 19.7109 21.2289 19.4514C21.2346 19.1919 21.173 18.9354 21.05 18.7069ZM11.7406 18.6211C11.5552 18.6211 11.3739 18.5661 11.2197 18.4631C11.0656 18.3601 10.9454 18.2137 10.8745 18.0424C10.8035 17.8711 10.7849 17.6826 10.8211 17.5007C10.8573 17.3188 10.9466 17.1518 11.0777 17.0207C11.2088 16.8896 11.3758 16.8003 11.5577 16.7641C11.7396 16.7279 11.9281 16.7465 12.0994 16.8175C12.2707 16.8884 12.4171 17.0086 12.5201 17.1627C12.6231 17.3169 12.6781 17.4982 12.6781 17.6836C12.6781 17.8067 12.6538 17.9286 12.6067 18.0424C12.5596 18.1561 12.4906 18.2595 12.4035 18.3465C12.3164 18.4336 12.2131 18.5026 12.0994 18.5497C11.9856 18.5968 11.8637 18.6211 11.7406 18.6211ZM12.7587 9.19219L12.4897 14.9109C12.4897 15.1098 12.4106 15.3006 12.27 15.4413C12.1293 15.5819 11.9386 15.6609 11.7397 15.6609C11.5407 15.6609 11.35 15.5819 11.2093 15.4413C11.0687 15.3006 10.9897 15.1098 10.9897 14.9109L10.7206 9.19453C10.7145 9.05793 10.7361 8.92151 10.7839 8.79341C10.8317 8.6653 10.9048 8.54813 10.9988 8.44888C11.0929 8.34963 11.2059 8.27033 11.3313 8.2157C11.4566 8.16108 11.5917 8.13225 11.7284 8.13094H11.7382C11.8759 8.13087 12.0122 8.15869 12.1388 8.21272C12.2654 8.26675 12.3797 8.34587 12.4749 8.44531C12.5701 8.54475 12.6442 8.66245 12.6926 8.7913C12.7411 8.92015 12.7629 9.05748 12.7568 9.195L12.7587 9.19219Z"-->
<!--                        fill="#E24C4B"/>-->
<!--                  </svg>-->
<!--                </div>-->

<!--                <div class="photo-warning-title" v-if="!requirements">-->
<!--                  <p>На фоне не должны быть другие люди и посторонние предметы;</p>-->
<!--                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <path-->
<!--                        d="M21.05 18.7069L13.0611 3.87094C12.4948 2.81906 10.9864 2.81906 10.4197 3.87094L2.43122 18.7069C2.30827 18.9352 2.24663 19.1916 2.25231 19.4508C2.258 19.7101 2.33081 19.9635 2.46365 20.1863C2.59648 20.409 2.7848 20.5935 3.01022 20.7217C3.23564 20.85 3.49046 20.9176 3.74981 20.918H19.729C19.9886 20.918 20.2437 20.8507 20.4694 20.7226C20.6952 20.5945 20.8838 20.4101 21.0169 20.1873C21.15 19.9644 21.2231 19.7109 21.2289 19.4514C21.2346 19.1919 21.173 18.9354 21.05 18.7069ZM11.7406 18.6211C11.5552 18.6211 11.3739 18.5661 11.2197 18.4631C11.0656 18.3601 10.9454 18.2137 10.8745 18.0424C10.8035 17.8711 10.7849 17.6826 10.8211 17.5007C10.8573 17.3188 10.9466 17.1518 11.0777 17.0207C11.2088 16.8896 11.3758 16.8003 11.5577 16.7641C11.7396 16.7279 11.9281 16.7465 12.0994 16.8175C12.2707 16.8884 12.4171 17.0086 12.5201 17.1627C12.6231 17.3169 12.6781 17.4982 12.6781 17.6836C12.6781 17.8067 12.6538 17.9286 12.6067 18.0424C12.5596 18.1561 12.4906 18.2595 12.4035 18.3465C12.3164 18.4336 12.2131 18.5026 12.0994 18.5497C11.9856 18.5968 11.8637 18.6211 11.7406 18.6211ZM12.7587 9.19219L12.4897 14.9109C12.4897 15.1098 12.4106 15.3006 12.27 15.4413C12.1293 15.5819 11.9386 15.6609 11.7397 15.6609C11.5407 15.6609 11.35 15.5819 11.2093 15.4413C11.0687 15.3006 10.9897 15.1098 10.9897 14.9109L10.7206 9.19453C10.7145 9.05793 10.7361 8.92151 10.7839 8.79341C10.8317 8.6653 10.9048 8.54813 10.9988 8.44888C11.0929 8.34963 11.2059 8.27033 11.3313 8.2157C11.4566 8.16108 11.5917 8.13225 11.7284 8.13094H11.7382C11.8759 8.13087 12.0122 8.15869 12.1388 8.21272C12.2654 8.26675 12.3797 8.34587 12.4749 8.44531C12.5701 8.54475 12.6442 8.66245 12.6926 8.7913C12.7411 8.92015 12.7629 9.05748 12.7568 9.195L12.7587 9.19219Z"-->
<!--                        fill="#E24C4B"/>-->
<!--                  </svg>-->

<!--                </div>-->

<!--                <div class="photo-success-title" v-if="requirements">-->
<!--                  <p>Все требования соблюдены</p>-->
<!--                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#039855"/>-->
<!--                    <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#039855"/>-->
<!--                    <path d="M11.3337 5.5L6.75033 10.0833L4.66699 8" stroke="white" stroke-width="1.66667"-->
<!--                          stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  </svg>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="main-left">-->
<!--              <h2>-->
<!--                Сделай фотографию профиля для сертификата и проверки работы камеры-->
<!--              </h2>-->
<!--              <p>-->
<!--                На фотографии лицо должно быть хорошо освещено-->
<!--              </p>-->


<!--              <div class="camera-upload-container">-->
<!--                <div class="video-wrapper">-->
<!--                  <video v-if="hasAccessCamera && !isPhotoTaken" ref="videoElement2" autoplay playsinline muted disablePictureInPicture-->
<!--                         class="camera-block"></video>-->

<!--                  <canvas ref="canvas" style="display: none;"></canvas>-->
<!--                  <img :src="photo" alt="Сделанное фото" width="153" height="153" v-if="isPhotoTaken"/>-->

<!--                  <div class="overlay">-->
<!--                    <div class="mask"></div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="upload-photo-labels" @click.stop="takePhoto">-->
<!--                  <p class="label-top">-->
<!--                    Сделать фото-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->

<!--          <div class="rules-btns-block">-->
<!--            <button class="test-prev-btn" @click="nextTab(tabs.accessMicrophone)">-->
<!--              {{ $t('about.back-button')}}-->
<!--            </button>-->

<!--            <button class="test-next-btn" @click="startTestPageRules"-->
<!--                    :disabled="!requirements"-->
<!--                    :class="{ disabled: !requirements }">-->
<!--              Начать тест-->
<!--              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M12.675 21.9508L22.1226 12.5008L12.6726 3.05078M1.875 12.5008H22.125H1.875Z" stroke="#FBFBFD"-->
<!--                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              </svg>-->
<!--            </button>-->
<!--          </div>-->
<!--        </section>-->

        <section v-if="currentTab=='start-test'" class="test-rules-block">
          <div class="d-flex justify-content-between">
            <div class="test-info">
              <div class="quiz-container">
                <div class="quiz-card" v-for="(quiz, index) in subjects" :key="index">
                  <p class="subject">{{$t('page_rules_21')}} {{ index + 1 }}</p>
                  <h3 class="title">{{ quiz[`name_${$i18n.locale}`] }}</h3>
                  <p class="description">{{ quiz.description }}</p>
                  <div class="quiz-footer">
                    <div class="quiz-info blue">
                      <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.849 20.1604H7.68035C6.90113 20.1604 6.27344 19.5804 6.27344 18.8604V5.95039C6.27344 5.23039 6.90113 4.65039 7.68035 4.65039H18.849C19.6282 4.65039 20.2559 5.23039 20.2559 5.95039V18.8604C20.2559 19.5804 19.6282 20.1604 18.849 20.1604Z" stroke="#15C0E6" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.97461 10.4297H14.3901" stroke="#15C0E6" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9.97461 14.3789H16.5546" stroke="#15C0E6" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <span class="text blue">{{quiz.questions}} {{$t('page_rules_22')}}</span>
                    </div>
                    <div class="quiz-info purple">
                      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.1437 21.3196C18.0369 21.3196 22.0037 17.3529 22.0037 12.4596C22.0037 7.56637 18.0369 3.59961 13.1437 3.59961C8.25045 3.59961 4.28369 7.56637 4.28369 12.4596C4.28369 17.3529 8.25045 21.3196 13.1437 21.3196Z" stroke="#6D5DD3" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.144 7.61914V12.4591L16.124 13.8791" stroke="#6D5DD3" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <span class="text purple">{{ quiz.time }} минут</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="ready-start-label">
            {{$t('page_rules_23')}}
          </div>

<!--          <button class="test-next-btn">-->
<!--            Начать тест-->
<!--            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M12.675 21.9508L22.1226 12.5008L12.6726 3.05078M1.875 12.5008H22.125H1.875Z" stroke="#FBFBFD"-->
<!--                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            </svg>-->
<!--          </button>-->

          <div class="rules-btns-block">
            <button class="test-prev-btn" @click="nextTab(tabs.accessMicrophone)">
              {{ $t('about.back-button')}}
            </button>

            <button class="test-next-btn" @click="startTestPageRules">
              {{$t('page_rules_24')}}
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.675 21.9508L22.1226 12.5008L12.6726 3.05078M1.875 12.5008H22.125H1.875Z" stroke="#FBFBFD"
                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>

        </section>

        <el-dialog
            :visible.sync="dialogWantExit"
            width="400px"
            class="modal-dialog-exit"
        >
          <template #title>
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2"/>
              <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8"/>
            </svg>
          </template>

          <div class="modal-dialog-title">
            Вы точно хотите выйти?
          </div>
          <div class="modal-dialog-title">
            Или продолжить?
          </div>
          <p class="modal-dialog-text">
            В случае завершения тестирования ответы сохранены не будут.
          </p>

          <div class="d-flex justify-content-between">
            <button class="modal-btn-test">
              К тесту
            </button>
            <button class="modal-btn-exit" @click="dialogWantExit=false">
              Выйти
            </button>
          </div>

        </el-dialog>

        <el-dialog
            :visible.sync="dialogHighNoise"
            width="400px"
            class="modal-dialog-exit"
        >
          <template #title>
            <svg width="69" height="53" viewBox="0 0 69 53" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
              <rect y="32.1699" width="6.12467" height="20.5687" rx="3.06234" fill="#E24C4B"/>
              <rect x="7.7207" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
              <rect x="7.7207" y="38.6992" width="6.12467" height="14.0386" rx="3.06234" fill="#E24C4B"/>
              <rect x="15.4414" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
              <rect x="62" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
              <rect x="15.4414" y="12.7676" width="6.12467" height="39.9707" rx="3.06234" fill="#E24C4B"/>
              <rect x="62" y="12.7676" width="6.12467" height="39.9707" rx="3.06234" fill="#E24C4B"/>
              <rect x="23.1602" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
              <rect x="23.1602" y="24.6758" width="6.12467" height="28.0631" rx="3.06234" fill="#E24C4B"/>
              <rect x="30.7012" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
              <rect x="30.7012" y="32.1699" width="6.12467" height="20.5687" rx="3.06234" fill="#E24C4B"/>
              <rect x="38.5098" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
              <rect x="38.5098" y="28.1152" width="6.12467" height="24.6231" rx="3.06234" fill="#E24C4B"/>
              <rect x="46.1426" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
              <rect x="54" width="6.12467" height="52.7377" rx="3.06234" fill="#F4F4F4"/>
              <rect x="46.1426" y="32.1699" width="6.12467" height="20.5687" rx="3.06234" fill="#E24C4B"/>
              <rect x="54" y="32.1699" width="6.12467" height="20.5687" rx="3.06234" fill="#E24C4B"/>
            </svg>
          </template>

          <div class="modal-dialog-title">
            {{$t('modal_9')}}
          </div>
          <p class="modal-dialog-text">
            {{$t('modal_10')}}
          </p>

          <div class="d-flex justify-content-between">
            <button class="modal-btn-test">
              {{$t('modal_4')}}
            </button>
            <button class="modal-btn-continue" @click="dialogWantExit=false">
              {{$t('modal_5')}}
            </button>
          </div>

        </el-dialog>

        <el-dialog
            :visible.sync="dialogWantAttemptPassTest"
            width="400px"
            class="modal-dialog-attempt"
        >
          <template #title>
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2"/>
              <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8"/>
            </svg>
          </template>

          <div class="modal-dialog-title">
            Попытка прохождения теста была отклонена.
          </div>
          <p class="modal-dialog-text">
            Прохождение теста было приостановлено длительное время. <strong>Вам необходимо начать тестирование
            сначала.</strong>
          </p>
          <p class="modal-dialog-text">
            <strong>Помните, что у вас только <span style="color: #D92D20">5 попыток</span></strong>
          </p>

          <div class="d-flex justify-content-between">
            <button class="modal-btn-test">
              Начать тест заново
            </button>
          </div>

        </el-dialog>

        <el-dialog
            :visible.sync="dialogWantCriticalViolations"
            width="400px"
            class="modal-dialog-attempt"
        >
          <template #title>
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2"/>
              <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8"/>
            </svg>
          </template>

          <div class="modal-dialog-title">
            Мы зафиксировали критическое количество нарушений!
          </div>
          <p class="modal-dialog-text">
            Дальнейшее прохождение тестирования невозможно. Не нарушайте правила олимпиады, в противном случае вы будете
            дисквалифицированы. <strong>Вам необходимо начать тестирование сначала.</strong>
          </p>
          <p class="modal-dialog-text">
            <strong>Помните, что у вас только <span style="color: #D92D20">5 попыток</span></strong>
          </p>

          <div class="d-flex justify-content-between">
            <button class="modal-btn-test">
              Начать тест заново
            </button>
          </div>

        </el-dialog>

        <el-dialog
            :visible.sync="dialogLogicalThinkingTestCompleted"
            width="510px"
            class="modal-dialog-test-completed"
        >
          <template #title>
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF"/>
              <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8"/>
              <path
                  d="M23.5 28L26.5 31L32.5 25M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18C33.5228 18 38 22.4772 38 28Z"
                  stroke="#039855" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </template>

          <div class="modal-dialog-title">
            Предмет Логическое мышление успешно завершен!
          </div>
          <p class="modal-dialog-text">
            После перехода к следующему предмету, вы не сможете вернуться к предмету Логическое мышление.
          </p>

          <div class="modal-dialog-test-passed">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g style="mix-blend-mode:multiply">
                <rect width="32" height="32" rx="16" fill="#039855" fill-opacity="0.11"/>
                <path
                    d="M9.33398 17.6664L15.7621 20.8805C15.8496 20.9242 15.8933 20.9461 15.9392 20.9547C15.9798 20.9623 16.0215 20.9623 16.0621 20.9547C16.108 20.9461 16.1517 20.9242 16.2392 20.8805L22.6673 17.6664M9.33398 14.3331L15.7621 11.119C15.8496 11.0752 15.8933 11.0534 15.9392 11.0448C15.9798 11.0372 16.0215 11.0372 16.0621 11.0448C16.108 11.0534 16.1517 11.0752 16.2392 11.119L22.6673 14.3331L16.2392 17.5471C16.1517 17.5909 16.108 17.6127 16.0621 17.6213C16.0215 17.6289 15.9798 17.6289 15.9392 17.6213C15.8933 17.6127 15.8496 17.5909 15.7621 17.5471L9.33398 14.3331Z"
                    stroke="#039855" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
            </svg>
            Логическое мышление
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                 class="modal-dialog-test-passed-tick">
              <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#039855"/>
              <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#039855"/>
              <path d="M11.3327 5.5L6.74935 10.0833L4.66602 8" stroke="white" stroke-width="1.66667"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>

          <div class="modal-dialog-test-next">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g style="mix-blend-mode:multiply">
                <rect width="32" height="32" rx="16" fill="#0055FF" fill-opacity="0.16"/>
                <path
                    d="M16.6672 9.33398L10.7295 16.4592C10.497 16.7383 10.3807 16.8778 10.3789 16.9956C10.3774 17.0981 10.423 17.1955 10.5027 17.2599C10.5944 17.334 10.776 17.334 11.1392 17.334H16.0006L15.3339 22.6673L21.2716 15.5421C21.5041 15.263 21.6204 15.1235 21.6222 15.0057C21.6237 14.9032 21.5781 14.8058 21.4984 14.7414C21.4067 14.6673 21.2251 14.6673 20.8619 14.6673H16.0006L16.6672 9.33398Z"
                    stroke="#3377FF" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
            </svg>
            Пространственное мышление
          </div>

          <h2>
            Убедитесь что вы соблюдаете правила олимпиады!
          </h2>

          <div class="modal-dialog-test-photos-list">
            <img src="/images/modal-test-img.png" width="129" height="100" alt=""/>
            <img src="/images/modal-test-img.png" width="129" height="100" alt=""/>
            <img src="/images/modal-test-img.png" width="129" height="100" alt=""/>
          </div>

          <button class="modal-dialog-test-completed-next">
            Продолжить
          </button>

        </el-dialog>
      </div>
    </div>
    <div class="mobile-view">
      <div class="main-wrapper-mobile">
        <div class="page-header-mobile">
          <div class="tabs-list-mobile" v-if="currentTab !== 'start-test'">
            <div class="tab-item-mobile" :class="{ 'active-tab' : currentTab == 'about-test' }">
              1
            </div>
            <svg width="32" height="2" viewBox="0 0 32 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="0.5" width="32" height="1" fill="white"/>
            </svg>
            <div class="tab-item-mobile" :class="{ 'active-tab' : currentTab == 'access-microphone' }">
              2
            </div>
            <svg width="32" height="2" viewBox="0 0 32 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="0.5" width="32" height="1" fill="white"/>
            </svg>
            <div class="tab-item-mobile" :class="{ 'active-tab' : currentTab == 'access-camera' }">
              3
            </div>
            <svg width="32" height="2" viewBox="0 0 32 2" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="0.5" width="32" height="1" fill="white"/>
            </svg>
            <div class="tab-item-mobile" :class="{ 'active-tab' : currentTab == 'final-check' }">
              4
            </div>
          </div>
          <div class="names-list-mobile" v-if="currentTab !== 'start-test'">
            <div class="name-item-mobile" :class="{ 'active-tab' : currentTab == 'about-test' }">
              О тесте
            </div>
            <div class="name-item-mobile" :class="{ 'active-tab' : currentTab == 'access-microphone' }">
              Микрофон
            </div>
            <div class="name-item-mobile" :class="{ 'active-tab' : currentTab == 'access-camera' }">
              Камера
            </div>
            <div class="name-item-mobile" :class="{ 'active-tab' : currentTab == 'final-check' }">
              Проверка
            </div>
          </div>

          <div class="start-test-title-mobile">
            Начать тест
          </div>
        </div>

        <section v-if="currentTab == 'about-test'">
          <div class="middle-wrapper-mobile">
            <div class="main-block-header">
              <h1>
                О тесте
              </h1>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 16V12" stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 8H12.01" stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="test-desc-mobile" v-for="subject in subjects">
              <h2>
                {{subject[`name_${$i18n.locale}`]}}
              </h2>
              <div class="test-desc-questions-time-mobile">
                <svg width="44" height="40" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <ellipse cx="21.6447" cy="20" rx="21.6447" ry="20" fill="#3377FF"/>
                  <path
                      d="M27.349 28.1604H16.1803C15.4011 28.1604 14.7734 27.5804 14.7734 26.8604V13.9504C14.7734 13.2304 15.4011 12.6504 16.1803 12.6504H27.349C28.1282 12.6504 28.7559 13.2304 28.7559 13.9504V26.8604C28.7559 27.5804 28.1282 28.1604 27.349 28.1604Z"
                      stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
                      stroke-linejoin="round"/>
                  <path d="M18.4746 18.4297H22.8901" stroke="white" stroke-width="1.2" stroke-miterlimit="10"
                        stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18.4746 22.3789H25.0546" stroke="white" stroke-width="1.2" stroke-miterlimit="10"
                        stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <div class="test-desc-text-mobile">
                  <p class="title">
                    {{subject.questions}} вопросов
                  </p>
                  <p class="subtitle">
                    Количество вопросов в тесте
                  </p>
                </div>
              </div>

              <div class="test-desc-questions-time-mobile">
                <svg width="44" height="40" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <ellipse cx="21.6447" cy="20" rx="21.6447" ry="20" fill="#3377FF"/>
                  <path
                      d="M22.1423 29.3196C27.438 29.3196 31.7309 25.3529 31.7309 20.4596C31.7309 15.5664 27.438 11.5996 22.1423 11.5996C16.8467 11.5996 12.5537 15.5664 12.5537 20.4596C12.5537 25.3529 16.8467 29.3196 22.1423 29.3196Z"
                      stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round"
                      stroke-linejoin="round"/>
                  <path d="M22.1426 15.6191V20.4591L25.3676 21.8791" stroke="white" stroke-width="1.2"
                        stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>


                <div class="test-desc-text-mobile">
                  <p class="title">
                    {{remainingTime}} минут
                  </p>
                  <p class="subtitle">
                    Время отведенное на данный раздел
                  </p>
                </div>
              </div>
            </div>
            <div class="proctoring-about">
              В олимпиаде используется "Прокторинг" обеспечения для академической честности участников.
            </div>

            <div class="rules-list-mobile">
              <h2>
                Правила
              </h2>
              <p>
                Сидите в тихом, хорошо освещённом месте.
              </p>
              <p>
                На столе только необходимые для экзамена вещи.
              </p>
              <p>
                Камера и микрофон должны работать.
              </p>
              <p>
                Нельзя пользоваться телефоном, шпаргалками или разговаривать с кем-либо
              </p>
            </div>

            <div class="proctoring-img">
              <img src="/images/proctoring-img.svg" width="319" height="194" alt=""/>
            </div>

            <button class="next-btn-mobile" @click="nextTab(tabs.accessMicrophone)">
              {{ $t('next_1')}}
              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.425 21.4508L22.8726 12.0008L13.4226 2.55078M2.625 12.0008H22.875H2.625Z" stroke="#FBFBFD"
                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </button>
          </div>
        </section>

        <section v-if="currentTab == 'access-microphone'">
          <div class="middle-wrapper-mobile">
            <div class="main-block-header">
              <h1>
                Доступ к микрофону
              </h1>
              <div @click="openModalMicrophone">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 16V12" stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 8H12.01" stroke="#959595" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
              </div>
              <div v-if="modalMicrophone" class="modal-microphone">
                Подробнее о доступе к микрофону:<br>
                Мы используем микрофон для фиксации нарушении, если вы или кто-то разговаривает. Потенциальные нарушения записываются и будут пересмотрены.
              </div>
            </div>

            <div class="rules-list-mobile rules-list-microphone-mobile">
              <h2>
                НЕ РАЗГОВАРИВАТЬ!
              </h2>
              <p>
                Вы должны быть <span class="blue-word">один</span> в тихом месте
              </p>
              <p>
                Во время тестирования вам <span class="blue-word">нельзя разговаривать</span>
              </p>
              <p>
                Вам <span class="blue-word">нельзя</span> читать вопрос вслух
              </p>
              <div class="noise-level-mobile">
                <svg width="48" height="38" viewBox="0 0 48 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.921875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="0.5" y="23.1562" width="4.23323" height="14.2166" rx="2.11661" fill="#E24C4B"/>
                  <rect x="5.83594" y="0.921875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="5.83594" y="13.8457" width="4.23323" height="23.5" rx="2.11661" fill="#E24C4B"/>
                  <rect x="11.1719" y="0.921875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="43.3525" y="0.921875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="11.1719" y="6.29102" width="4.23323" height="31.1029" rx="2.11661" fill="#E24C4B"/>
                  <rect x="43.3525" y="9.74609" width="4.23323" height="27.6268" rx="2.11661" fill="#E24C4B"/>
                  <rect x="16.5078" y="0.921875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="16.5078" y="17.9766" width="4.23323" height="19.3965" rx="2.11661" fill="#E24C4B"/>
                  <rect x="21.7207" y="0.921875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="21.7197" y="10.0254" width="4.23323" height="27.6471" rx="2.11661" fill="#E24C4B"/>
                  <rect x="27.1182" y="0.921875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="27.1182" y="5.14844" width="4.23323" height="32.4853" rx="2.11661" fill="#E24C4B"/>
                  <rect x="32.3926" y="0.921875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="37.8232" y="0.921875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="32.3926" y="8.64258" width="4.23323" height="29.0294" rx="2.11661" fill="#E24C4B"/>
                  <rect x="37.8232" y="23.1562" width="4.23323" height="14.2166" rx="2.11661" fill="#E24C4B"/>
                </svg>
                Высокий уровень шума
              </div>
              <div class="noise-level-mobile">
                <svg width="48" height="38" viewBox="0 0 48 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.671875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="0.5" y="22.9062" width="4.23323" height="14.2166" rx="2.11661" fill="#FAD059"/>
                  <rect x="5.83594" y="0.671875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="5.83594" y="27.4199" width="4.23323" height="9.70313" rx="2.11661" fill="#FAD059"/>
                  <rect x="11.1719" y="0.671875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="43.3525" y="0.671875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="11.1719" y="18.4824" width="4.23323" height="18.6618" rx="2.11661" fill="#FAD059"/>
                  <rect x="43.3525" y="9.49609" width="4.23323" height="27.6268" rx="2.11661" fill="#FAD059"/>
                  <rect x="16.5078" y="0.671875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="16.5078" y="17.7266" width="4.23323" height="19.3965" rx="2.11661" fill="#FAD059"/>
                  <rect x="21.7207" y="0.671875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="21.7207" y="22.9062" width="4.23323" height="14.2166" rx="2.11661" fill="#FAD059"/>
                  <rect x="27.1182" y="0.671875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="27.1182" y="20.1035" width="4.23323" height="17.0189" rx="2.11661" fill="#FAD059"/>
                  <rect x="32.3926" y="0.671875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="37.8232" y="0.671875" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="32.3926" y="22.9062" width="4.23323" height="14.2166" rx="2.11661" fill="#FAD059"/>
                  <rect x="37.8232" y="22.9062" width="4.23323" height="14.2166" rx="2.11661" fill="#FAD059"/>
                </svg>
                Средний уровень шума
              </div>
              <div class="noise-level-mobile">
                <svg width="48" height="37" viewBox="0 0 48 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.142578" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="0.5" y="22.377" width="4.23323" height="14.2166" rx="2.11661" fill="#018837"/>
                  <rect x="5.83594" y="0.142578" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="5.83594" y="26.8906" width="4.23323" height="9.70313" rx="2.11661" fill="#018837"/>
                  <rect x="11.1719" y="0.142578" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="43.3525" y="0.142578" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="11.1719" y="27.6289" width="4.23323" height="8.98529" rx="2.11661" fill="#018837"/>
                  <rect x="43.3525" y="28.3203" width="4.23323" height="8.29412" rx="2.11661" fill="#018837"/>
                  <rect x="16.5078" y="0.142578" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="16.5078" y="28.9473" width="4.23323" height="7.60294" rx="2.11661" fill="#018837"/>
                  <rect x="21.7207" y="0.142578" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="21.7197" y="30.6719" width="4.23323" height="6.22059" rx="2.11661" fill="#018837"/>
                  <rect x="27.1182" y="0.142578" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="27.1182" y="29.252" width="4.23323" height="7.60294" rx="2.11661" fill="#018837"/>
                  <rect x="32.3926" y="0.142578" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="37.8232" y="0.142578" width="4.23323" height="36.4511" rx="2.11661" fill="white"/>
                  <rect x="32.3926" y="27.9062" width="4.23323" height="8.98529" rx="2.11661" fill="#018837"/>
                  <rect x="37.8232" y="28.5977" width="4.23323" height="8.29412" rx="2.11661" fill="#018837"/>
                </svg>

                Низкий уровень шума
              </div>
            </div>

            <div class="soundbar-mobile">
              <img src="/images/soundbar-mobile.svg" alt=""/>

              <div class="soundbar-block">
                <p v-if="!hasMicrophoneAccess" class="no-sound">Звука нет</p>
                <p v-else v-html="noiseMessage">{{ noiseMessage }}</p>
                <div class="soundbar" :class="{ disabled: !hasMicrophoneAccess }">
                  <div
                      v-for="(bar, index) in bars"
                      :key="index"
                      class="bar"
                      :style="{ height: bar.height + '%', backgroundColor: bar.color }"
                  ></div>
                </div>
              </div>

            </div>

            <button class="microphone-allow-access-mobile" @click="requestAccess" v-if="!hasMicrophoneAccess">
              Разрешить доступ к микрофону
            </button>
            <div class="microphone-access-granted-mobile" v-if="hasMicrophoneAccess">
              Доступ к микрофону получен

              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="1.07812" width="15" height="15" rx="7.5" fill="#039855"/>
                <rect x="0.5" y="1.07812" width="15" height="15" rx="7.5" stroke="#039855"/>
                <path d="M11.3337 6.07812L6.75033 10.6615L4.66699 8.57813" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </div>

            <div class="d-flex" style="gap: 10px">

              <button class="prev-btn-mobile" @click="nextTab(tabs.aboutTest)">
                {{ $t('about.back-button')}}
              </button>

              <button class="next-btn-mobile" :disabled="!hasMicrophoneAccess || !isQuiet" @click="nextTab(tabs.accessCamera)"
                      :class="{'disabled-btn' : !hasMicrophoneAccess || !isQuiet}">
                {{ $t('next_1')}}
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.425 21.4508L22.8726 12.0008L13.4226 2.55078M2.625 12.0008H22.875H2.625Z" stroke="#FBFBFD"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </button>
            </div>
          </div>
        </section>

        <section v-if="currentTab == 'access-camera'">
          <div class="middle-wrapper-mobile">
            <div class="main-block-header">
              <h1>
                Доступ к камере
              </h1>
              <div @click="openModalMicrophone">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 16V12" stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 8H12.01" stroke="#959595" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
              </div>
              <div v-if="modalMicrophone" class="modal-microphone">
                Подробнее о доступе к микрофону:<br>
                Мы используем микрофон для фиксации нарушении, если вы или кто-то разговаривает. Потенциальные нарушения записываются и будут пересмотрены.
              </div>
            </div>

            <div class="rules-list-mobile rules-list-microphone-mobile">
              <h2>
                НЕ РАЗГОВАРИВАТЬ!
              </h2>
              <div>
                Во время тестирования камера будет делать фотографии для проверки того, что вы правильно сдаете тест.
              </div>
              <p>
                Ваше лицо должно быть <span class="blue-word">всегда</span> в кадре
              </p>
              <p>
                Вы <span class="blue-word">самостоятельно</span> должны сдавать тест, <span class="blue-word">без посторонних</span>
              </p>
              <p>
                В помещении должно быть <span class="blue-word">светло</span> и ваше лицо должно быть <span class="blue-word">хорошо освещено</span>
              </p>
            </div>

            <div class="camera-live-mobile">
              <img src="/images/camera-man-mobile.svg" alt=""/>

              <div class="camera-live-block" v-if="isShowCameraLiveBlock">
                <div v-if="!hasAccessCamera">
                  <img src="/images/no-camera.svg" alt=""/>
                </div>
                <div v-else class="camera-live-container">
                  <video v-if="hasAccessCamera" ref="videoElement" autoplay playsinline muted disablePictureInPicture></video>
                </div>
              </div>

            </div>

            <button class="microphone-allow-access-mobile" @click="requestAccessCamera" v-if="!hasAccessCamera">
              Разрешить доступ к камере
            </button>
            <div class="microphone-access-granted-mobile" v-if="hasAccessCamera">
              Доступ к камере получен

              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="1.07812" width="15" height="15" rx="7.5" fill="#039855"/>
                <rect x="0.5" y="1.07812" width="15" height="15" rx="7.5" stroke="#039855"/>
                <path d="M11.3337 6.07812L6.75033 10.6615L4.66699 8.57813" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

            </div>

            <div class="d-flex" style="gap: 10px">

              <button class="prev-btn-mobile" @click="nextTab(tabs.accessMicrophone)">
                {{ $t('about.back-button')}}
              </button>

              <button class="next-btn-mobile" :disabled="!hasAccessCamera" @click="nextTab(tabs.finalCheck)"
                      :class="{'disabled-btn' : !hasAccessCamera}">
                {{ $t('next_1')}}
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.425 21.4508L22.8726 12.0008L13.4226 2.55078M2.625 12.0008H22.875H2.625Z" stroke="#FBFBFD"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </button>
            </div>
          </div>
        </section>

        <section v-if="currentTab == 'final-check'">
          <div class="middle-wrapper-mobile">
            <div class="main-block-header">
              <h1>
                Финальная проверка
              </h1>
              <div @click="openModalMicrophone">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 16V12" stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 8H12.01" stroke="#959595" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"/>
                </svg>
              </div>
              <div v-if="modalMicrophone" class="modal-microphone">
                Подробнее о доступе к микрофону:<br>
                Мы используем микрофон для фиксации нарушении, если вы или кто-то разговаривает. Потенциальные нарушения записываются и будут пересмотрены.
              </div>
            </div>

            <div class="take-photo-container">
              <div class="camera-upload-container">
                <div class="video-wrapper" v-if="isShowCameraLiveBlock">
                  <video v-if="hasAccessCamera && !isPhotoTaken" ref="videoElement2" autoplay playsinline muted disablePictureInPicture
                         class="camera-block"></video>

                  <canvas ref="canvas" style="display: none;"></canvas>
                  <img :src="photo" alt="Сделанное фото" width="80" height="80" v-if="isPhotoTaken"/>
                  <div class="take-new-photo" @click="takeNewPhoto" v-if="isPhotoTaken">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.54403 1.45312L1.45312 8.54403M1.45312 1.45312L8.54403 8.54403" stroke="white" stroke-width="1.18182" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </div>
              </div>
              <button class="take-photo-btn" @click.stop="takePhoto">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 6.5L9.5 3.5H15.5L17 6.5H8Z" stroke="#475467" stroke-width="2" stroke-linejoin="round"/>
                  <path d="M21 6.5H4C3.17157 6.5 2.5 7.17157 2.5 8V20C2.5 20.8284 3.17157 21.5 4 21.5H21C21.8284 21.5 22.5 20.8284 22.5 20V8C22.5 7.17157 21.8284 6.5 21 6.5Z" stroke="#475467" stroke-width="2" stroke-linejoin="round"/>
                  <path d="M12.5 18C14.7092 18 16.5 16.2092 16.5 14C16.5 11.7908 14.7092 10 12.5 10C10.2908 10 8.5 11.7908 8.5 14C8.5 16.2092 10.2908 18 12.5 18Z" stroke="#475467" stroke-width="2" stroke-linejoin="round"/>
                </svg>
                Сделать фото
              </button>
            </div>

            <div class="rules-list-mobile rules-list-final-check-mobile">
              <h2>
                Основные требования
              </h2>
              <div>
                Направьте веб-камеру так, чтобы ваше лицо оказалось в центре овала. Убедитесь, что ваше лицо хорошо видно.
              </div>
              <p>
                Лицо должно быть открыто и находиться в кадре;
              </p>
              <p>
                Не должно быть лишних предметов (маска, очки и т.п.);
              </p>
              <p>
                На фоне не должны быть другие люди и посторонние предметы;
              </p>
              <p>
                В помещении должно быть светло и ваше лицо должно быть хорошо освещено
              </p>

              <div class="requirements-good" v-if="isRequirementsGood">
                Все требования соблюдены

                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="1.07812" width="15" height="15" rx="7.5" fill="#039855"/>
                  <rect x="0.5" y="1.07812" width="15" height="15" rx="7.5" stroke="#039855"/>
                  <path d="M11.3337 6.07812L6.75033 10.6615L4.66699 8.57813" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </div>

              <div class="requirements-bad" v-if="!isRequirementsGood">
                Требования не соблюдены

                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.55 19.2069L13.5611 4.37094C12.9948 3.31906 11.4864 3.31906 10.9197 4.37094L2.93122 19.2069C2.80827 19.4352 2.74663 19.6916 2.75231 19.9508C2.758 20.2101 2.83081 20.4635 2.96365 20.6863C3.09648 20.909 3.2848 21.0935 3.51022 21.2217C3.73564 21.35 3.99046 21.4176 4.24981 21.418H20.229C20.4886 21.418 20.7437 21.3507 20.9694 21.2226C21.1952 21.0945 21.3838 20.9101 21.5169 20.6873C21.65 20.4644 21.7231 20.2109 21.7289 19.9514C21.7346 19.6919 21.673 19.4354 21.55 19.2069ZM12.2406 19.1211C12.0552 19.1211 11.8739 19.0661 11.7197 18.9631C11.5656 18.8601 11.4454 18.7137 11.3745 18.5424C11.3035 18.3711 11.2849 18.1826 11.3211 18.0007C11.3573 17.8188 11.4466 17.6518 11.5777 17.5207C11.7088 17.3896 11.8758 17.3003 12.0577 17.2641C12.2396 17.2279 12.4281 17.2465 12.5994 17.3175C12.7707 17.3884 12.9171 17.5086 13.0201 17.6627C13.1231 17.8169 13.1781 17.9982 13.1781 18.1836C13.1781 18.3067 13.1538 18.4286 13.1067 18.5424C13.0596 18.6561 12.9906 18.7595 12.9035 18.8465C12.8164 18.9336 12.7131 19.0026 12.5994 19.0497C12.4856 19.0968 12.3637 19.1211 12.2406 19.1211ZM13.2587 9.69219L12.9897 15.4109C12.9897 15.6098 12.9106 15.8006 12.77 15.9413C12.6293 16.0819 12.4386 16.1609 12.2397 16.1609C12.0407 16.1609 11.85 16.0819 11.7093 15.9413C11.5687 15.8006 11.4897 15.6098 11.4897 15.4109L11.2206 9.69453C11.2145 9.55793 11.2361 9.42151 11.2839 9.29341C11.3317 9.1653 11.4048 9.04813 11.4988 8.94888C11.5929 8.84963 11.7059 8.77033 11.8313 8.7157C11.9566 8.66108 12.0917 8.63225 12.2284 8.63094H12.2382C12.3759 8.63087 12.5122 8.65869 12.6388 8.71272C12.7654 8.76675 12.8797 8.84587 12.9749 8.94531C13.0701 9.04475 13.1442 9.16245 13.1926 9.2913C13.2411 9.42015 13.2629 9.55748 13.2568 9.695L13.2587 9.69219Z" fill="#E24C4B"/>
                </svg>

              </div>
            </div>

<!--            <button class="microphone-allow-access-mobile" @click="requestAccessCamera" v-if="!hasAccessCamera">-->
<!--              Разрешить доступ к камере-->
<!--            </button>-->


            <div class="d-flex" style="gap: 10px">

              <button class="prev-btn-mobile" @click="nextTab(tabs.accessCamera)">
                {{ $t('about.back-button')}}
              </button>

              <button class="next-btn-mobile" :disabled="isRequirementsGood" @click="startTestPageRules">
                      :class="{'disabled-btn' : isRequirementsGood}">
                Начать тест
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.425 21.4508L22.8726 12.0008L13.4226 2.55078M2.625 12.0008H22.875H2.625Z" stroke="#FBFBFD"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </button>
            </div>
          </div>
        </section>

<!--        <section v-if="currentTab == 'start-test'">-->
<!--          <div class="middle-wrapper-mobile">-->
<!--            <div class="rules-list-mobile rules-list-final-check-mobile">-->
<!--              <h2>-->
<!--                Правила-->
<!--              </h2>-->
<!--              <p>-->
<!--                Вы должны ответить на каждый вопрос до завершения времени-->
<!--              </p>-->
<!--              <p>-->
<!--                Вы не можете ставить на паузы тест-->
<!--              </p>-->
<!--              <p>-->
<!--                Вы должны быть в тихом месте-->
<!--              </p>-->
<!--              <p>-->
<!--                Вы должны самостоятельно сдавать тест-->
<!--              </p>-->
<!--              <p>-->
<!--                Вам нельзя использовать книги, записи или другие цифровые устройства-->
<!--              </p>-->

<!--            </div>-->

<!--            <div class="ready-start-label-mobile">-->
<!--              Вы готовы начать?-->
<!--            </div>-->

<!--            <button class="next-btn-mobile" @click="startTestPageRules">-->
<!--              Начать тест-->
<!--              <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M13.425 21.4508L22.8726 12.0008L13.4226 2.55078M2.625 12.0008H22.875H2.625Z" stroke="#FBFBFD"-->
<!--                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              </svg>-->

<!--            </button>-->

<!--            <button class="prev-btn-mobile" @click="nextTab(tabs.accessCamera)">-->
<!--              {{ $t('about.back-button')}}-->
<!--            </button>-->

<!--          </div>-->
<!--        </section>-->




      </div>
    </div>

    <el-dialog
        :visible="dialogNoFace"
        width="400px"
        class="modal-dialog-exit"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
    >
      <template #title>
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEE4E2"/>
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#FEF3F2" stroke-width="8"/>
          <path d="M25.5 26C27.433 26 29 24.433 29 22.5C29 20.567 27.433 19 25.5 19C23.567 19 22 20.567 22 22.5C22 24.433 23.567 26 25.5 26Z" stroke="#FF5257" stroke-width="2" stroke-linejoin="round"/>
          <path d="M32.5 31.5L36.5 35.5" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M32.5 35.5L36.5 31.5" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M29.5 30H25.4C23.1598 30 22.0397 30 21.1841 30.436C20.4314 30.8195 19.8195 31.4314 19.436 32.184C19 33.0397 19 34.1598 19 36.4V37H29.5" stroke="#FF5257" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </template>

      <div class="modal-dialog-title">
        {{$t('modal_1')}}
      </div>
      <p class="modal-dialog-text">
        {{$t('modal_2')}}
      </p>
      <p class="modal-dialog-text">
        {{$t('modal_3')}}
      </p>

      <div class="d-flex justify-content-between">
<!--        <button class="modal-btn-test">-->
<!--          {{$t('modal_4')}}-->
<!--        </button>-->
        <button class="modal-btn-continue" style="margin: 0 auto" @click="dialogNoFace=false" :disabled="!isFaceDetected">
          {{$t('modal_5')}}
        </button>
      </div>

    </el-dialog>
    <div class="loader-block" v-if="isLoading">
      <div class="spinner"></div>
    </div>

  </div>
</template>

<script>
import fa from "element-ui/src/locale/lang/fa";
import Vue from "vue";
import * as faceapi from 'face-api.js';

export default {
  name: "AboutTest",
  data() {
    return {
      isLoadingFace: false,
      isLoading: false,
      dialogNoFace: false,
      timer: 11,
      isSubjectsLoaded: false,
      isShowCameraLiveBlock : false,
      subjects : [],
      isRequirementsGood: false,
      modalMicrophone: false,
      dialogLogicalThinkingTestCompleted: false,
      dialogHighNoise: false,
      dialogWantExit: false,
      dialogWantAttemptPassTest: false,
      dialogWantCriticalViolations: false,
      tabs : {
        aboutTest: 'about-test',
        aboutTest2: 'about-test-2',
        accessMicrophone: 'access-microphone',
        accessCamera: 'access-camera',
        finalCheck: 'final-check',
        startTest: 'start-test'
      },
      currentTab : 'about-test',
      fileList: [],
      hasMicrophoneAccess: false,
      hasCameraAccess: false,
      audioContext: null,
      analyser: null,
      microphone: null,
      bars: Array(10).fill({ height: 100, color: 'gray' }),
      isQuiet: true,
      noiseMessage: '',
      noiseLevel: 0,
      hasAccessCamera: false,
      requirements : false,
      streamCamera : null,
      accessCheckInterval: null,
      microphoneStream: null,
      animationFrameId: null,
      showTooltip: false,
      lowNoiseLevel : this.$store.state.proctoring.lowNoiseLevel,
      highNoiseLevel : this.$store.state.proctoring.highNoiseLevel,
      photo: '',
      isPhotoTaken : false,
      remainingTime: 0,
      speed: 0,
      userQuizId: null,
      currentSubject: {
        subject_data: {
          name_ru: '',
          name_kz: '',
        }
      },
      subject_id: null,
      quizData: {
        subjects: []
      },
      currentSubjectIndex: 0,
      currentQuizSubject: {
        questions: [],
      },
      questionCount: 0,
      modelsLoaded: false,
      isFaceDetected: false,
      test_lang: 'ru',
    }
  },
  computed: {
    isButtonActive() {
      return this.timer === 0 && this.isSubjectsLoaded;
    },
  },
  async mounted() {
    localStorage.setItem('userIIN', this.$route.query.iin)
    await this.loadModels()

    const width = window.innerWidth;
    let barsCount = 70;

    if (width < 400) {
      barsCount = 15;
    } else if (width < 500) {
      barsCount = 20;
    } else if (width < 1000) {
      barsCount = 25;
    }

    if (width < 1000) {
      this.isShowCameraLiveBlock = true
    }

    this.bars = Array(barsCount).fill({ height: 100, color: 'gray' });

    this.MeasureConnectionSpeed()
    this.MeasureConnectionSpeed()
    this.MeasureConnectionSpeed()
    this.isLoading = true
    this.$http.get(API_ROOT + `/api/quiz/${this.$route.params.token}/subjects`)
        .then((res) => {
          this.isSubjectsLoaded = true
          if (res.body) {
            localStorage.setItem('quiz-token', this.$route.params.token);
            localStorage.setItem('test_lang', res.body.test_language);
            localStorage.setItem('user_quiz_id', res.body.user_quiz_id)
            localStorage.setItem('user_id', res.body.user_id)
            localStorage.setItem('timer', res.body.remaining_time);
            localStorage.removeItem('violationsNumber');
            this.remainingTime = Number(res.body.remaining_time) / 60;
            this.userQuizId = res.body.user_quiz_id
            this.test_lang = res.body.test_language
            this.$i18n.locale = res.body.test_language;
          }

          if (res.body.data) {
            localStorage.setItem('quiz-data', JSON.stringify(res.body.data));
            localStorage.setItem('quiz', JSON.stringify(res.body.data));
            this.subjects = res.body.data.subjects
            console.log('this.subjects', this.subjects)
          }
          this.startTimer()
        })
        .catch( (error) => {
          this.isSubjectsLoaded = false
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })

  },
  methods: {
    startTimer() {
      const countdown = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        }
        if (this.timer === 0) {
          clearInterval(countdown);
        }
      }, 1000);
    },
    updateAudio(status) {
      this.$store.commit('updatePermissionAudio', status);
    },
    updatePhoto(photo) {
      this.$store.commit('setPhoto', photo);
    },
    showResults(startTime, endTime) {
      var downloadSize = 24518; //bytes
      var duration = (endTime - startTime) / 1000;
      var bitsLoaded = downloadSize * 8;
      var speedBps = (bitsLoaded / duration).toFixed(2);
      var speedKbps = (speedBps / 1024).toFixed(2);
      var speedMbps = (speedKbps / 1024).toFixed(2);
      console.log('speedMbps', speedMbps)
      if (this.speed < speedMbps) {
        this.speed = speedMbps
        console.log('this.speed', this.speed)
      }

    },
    MeasureConnectionSpeed() {
      let imageAddr = "https://1000bala.elumiti.kz/images/1000-BALA-logo.svg";
      let startTime, endTime;
      let download = new Image();
      const showResult = this.showResults.bind(this)
      download.onload = function () {
        endTime = (new Date()).getTime();
        showResult(startTime, endTime);
      }
      startTime = (new Date()).getTime();
      let cacheBuster = "?nnn=" + startTime;
      download.src = imageAddr + cacheBuster;
    },
    startTestPageRules() {
      this.$http.post(window.API_ROOT + '/api/store-network-speed', {
        'network_speed': this.speed,
        user_quiz_id: this.userQuizId,

      }).then((res) => {
        if (!Array.isArray(this.subjects) || this.subjects.length === 0) {
          console.error('Ошибка: subjects не является массивом или пуст.');
          return;
        }

        const initialId = this.subjects[0]?.id;
        if (!initialId) {
          console.error('Ошибка: initialId не определён.');
          return;
        }

        this.$router.push({ name: 'about-subject', params: { id: this.subjects[0].id } });
      })

      // if (!Array.isArray(this.subjects) || this.subjects.length === 0) {
      //   console.error('Ошибка: subjects не является массивом или пуст.');
      //   return;
      // }
      //
      // const initialId = this.subjects[0]?.id;
      // if (!initialId) {
      //   console.error('Ошибка: initialId не определён.');
      //   return;
      // }
      //
      // this.$router.push({ name: 'about-subject', params: { id: this.subjects[0].id } });

      // this.$http
      //     .get(`${API_ROOT}/api/quiz/${this.$route.params.token}/${initialId}/start`)
      //     .then(res => {
      //       if (!res.body?.questions || res.body.questions.length == 0) {
      //         console.error('Ошибка: Вопросы отсутствуют в ответе API.');
      //         return;
      //       }
      //
      //       localStorage.setItem('current-quiz-subject', JSON.stringify(res.body))
      //       const nextId = res.body.questions[0].id;
      //       this.$router.push({ name: 'test-page', params: { id: nextId } });
      //     })
      //     .catch(error => {
      //       console.error('Ошибка при запросе к API:', error);
      //     });
    },
    takeNewPhoto() {
      this.isPhotoTaken = false

      setTimeout(() => {
        const videoElement = this.$refs.videoElement2;
        if (videoElement) {
          videoElement.srcObject = this.streamCamera;
        } else {
          console.error('Видео элемент не найден');
        }
      }, 500)
    },
    openModalMicrophone() {
      this.modalMicrophone = !this.modalMicrophone
    },
    async loadModels() {
      try {
        const MODEL_URL = '/models';
        await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
        await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
        await faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL);
        await faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL);
        this.modelsLoaded = true;
      } catch (error) {
        console.error('Ошибка загрузки моделей:', error);
      }
    },
    async takePhoto() {
      if (!faceapi.nets.ssdMobilenetv1.isLoaded) {
        console.error("Модели ещё не загружены!");
        alert("Модели загружаются, попробуйте позже!");
        return;
      }

      const video = this.$refs.videoElement2;
      const canvas = this.$refs.canvas;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Проверяем, есть ли лицо на фото
      const detections = await faceapi.detectSingleFace(canvas)
          .withFaceLandmarks()
          .withFaceDescriptor();

      if (!detections) {
        console.error("Лицо не обнаружено! Фото не сохранено.");
        Vue.toastr({
          message: 'Ошибка',
          description: "Лицо не обнаружено! Сделайте фото заново.",
          type: 'warning',
          duration: 10000
        });
        this.requirements = false
        return;
      }

      // Если лицо найдено, сохраняем фото
      this.photo = canvas.toDataURL("image/png");
      this.isPhotoTaken = true;
      this.updatePhoto(this.photo);
      this.requirements = true
      console.log("Фото успешно сохранено!");
    },
    nextTab(tab) {
      this.currentTab = tab
    },
    handlePreview(file) {
      console.log('Preview file:', file);
    },
    handleRemove(file, fileList) {
      console.log('Remove file:', file, fileList);
    },
    updateStreamCamera(src) {
      this.$store.commit('updateStreamCamera', src);
    },
    updateStreamAudio(src) {
      this.$store.commit('updateStreamAudio', src);
    },
    getCameraConstraints() {
      if (/Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        // Смартфон или планшет
        return { video: { facingMode: "user" } }; // Фронтальная камера
      } else {
        // Компьютер
        return { video: true }; // Любая доступная камера
      }
    },
    async detectFaces() {
      if (!this.modelsLoaded) return;
      this.isLoadingFace = true

      const video = this.$refs.videoElement;
      const canvas = this.$refs.canvas1;

      // video.width = video.videoWidth
      // video.height = video.videoHeight
      // canvas.width = video.videoWidth;
      // canvas.height = video.videoHeight;

      const displaySize = { width: video.width, height: video.height };
      faceapi.matchDimensions(canvas, displaySize);

      let faceDetected = null;
      let lastDetectionTime = Date.now();

      const draw = async () => {
        const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
            .withFaceLandmarks();

        const resizedDetections = faceapi.resizeResults(detections, displaySize);
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);

        if (resizedDetections.length > 1) {
          if (faceDetected !== "multiple") {
            faceDetected = "multiple";
            this.dialogManyFaces = true
            // this.sendEvent('multiple_faces')
          }
          lastDetectionTime = Date.now();
        } else if (resizedDetections.length === 1) {
          if (faceDetected !== "single") {

            faceDetected = "single";
            this.isFaceDetected = true
            if (this.dialogNoFace) {
              console.log("Лицо появилось - закрываем модальное окно!");
            }
          }
          lastDetectionTime = Date.now();
        } else {
          if (Date.now() - lastDetectionTime > 500) {
            if (faceDetected !== "none") {

              faceDetected = "none";
              this.isFaceDetected = false
              this.dialogNoFace = true
              // this.sendEvent('no_face')
            }
          }
        }
        this.isLoadingFace = false

        faceapi.draw.drawDetections(canvas, resizedDetections);
        faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);

        requestAnimationFrame(draw);
      };

      draw();
    },
    async requestAccessCamera() {
      try {

        this.streamCamera = await navigator.mediaDevices.getUserMedia(this.getCameraConstraints());
        this.streamCamera.getVideoTracks()[0].onended = () => {
          window.location.reload();
        };
        this.hasAccessCamera = true;

        setTimeout(async () => {
          const videoElement = this.$refs.videoElement;
          if (videoElement) {
            videoElement.srcObject = this.streamCamera;
            this.updateStreamCamera(this.streamCamera)
            console.log('videoElement.srcObject', videoElement.srcObject)
            await this.loadModels()
            setTimeout(async () => {
              await this.detectFaces();
            },500)
          } else {
            console.error('Видео элемент не найден');
          }
        }, 500)

      } catch (error) {
        console.error('Ошибка доступа к камере:', error);

        Vue.toastr({
          message: 'Ошибка',
          description: 'Ошибка доступа к камере. Проверьте камеру.',
          type: 'error'
        })
        this.hasAccessCamera = false;
      }
    },
    getRandomNumber(normalized) {
      const numbers = [1, 3, 7, 9, 10];
      const randomIndex = Math.floor(Math.random() * numbers.length);

      if (normalized <= 0.3) {
        return numbers[randomIndex] * 3;
      } else if (normalized <= 0.7) {
        return numbers[randomIndex] * 10;
      } else {
        return numbers[randomIndex] * 12;
      }
    },
    async requestAccessCameraMirco() {
      await this.requestAccess()
      await this.requestAccessCamera()
    },
    async requestAccess() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.microphoneStream = stream;

        stream.getAudioTracks()[0].onended = () => {
          window.location.reload();
        };

        this.updateStreamAudio(stream)
        this.hasMicrophoneAccess = true;
        this.updateAudio(true)
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.microphone = this.audioContext.createMediaStreamSource(stream);
        this.analyser = this.audioContext.createAnalyser();
        this.microphone.connect(this.analyser);
        this.analyser.fftSize = 256;

        console.log('stream111', stream)
        this.startMicrophoneAccessCheck();

        this.startVisualizing();
      } catch (error) {
        console.error('Ошибка доступа к микрофону:', error);

        Vue.toastr({
          message: 'Ошибка',
          description: 'Ошибка доступа к микрофону. Проверьте микрофон.',
          type: 'error'
        })

        this.hasMicrophoneAccess = false;
        this.updateAudio(false)
      }
    },
    startMicrophoneAccessCheck() {
      if (this.accessCheckInterval) clearInterval(this.accessCheckInterval);

      this.accessCheckInterval = setInterval(async () => {
        if (this.microphoneStream && !this.microphoneStream.active) {
          // Поток больше не активен
          this.hasMicrophoneAccess = false;
          console.warn('Поток микрофона больше не активен');
          clearInterval(this.accessCheckInterval);
          cancelAnimationFrame(this.animationFrameId);
          this.bars = Array(10).fill({ height: 100, color: 'gray' })
        } else {
          // Дополнительно проверяем через Permissions API
          const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
          if (permissionStatus.state !== 'granted') {
            this.hasMicrophoneAccess = false;
            console.warn('Доступ к микрофону был отозван через настройки браузера');
            clearInterval(this.accessCheckInterval);
            cancelAnimationFrame(this.animationFrameId);
            this.bars = Array(10).fill({ height: 100, color: 'gray' })
          }
        }
      }, 2000);
    },
    startVisualizing() {
      const dataArray = new Uint8Array(this.analyser.frequencyBinCount);

      const updateBars = () => {
        this.analyser.getByteFrequencyData(dataArray);
        const average = dataArray.reduce((a, b) => a + b, 0) / dataArray.length;
        const normalized = Math.min(average / 100, 1);

        this.noiseLevel = 20 * Math.log10(average / 255) + 90;

        let color = 'gray';
        if (this.noiseLevel <= this.lowNoiseLevel) { // Низкий уровень шума (Тихо)
          color = 'green';
          this.noiseMessage = `<span style="color: #018837;" class="noise-message">${this.$t('page_rules_9')}</span>`;
          this.isQuiet = true;
        } else if (this.noiseLevel > this.lowNoiseLevel && this.noiseLevel <= this.highNoiseLevel) { // Средний уровень шума (Шумно)
          color = 'yellow';
          this.noiseMessage = `<span style="color: #E24C4B;;" class="noise-message">${this.$t('page_rules_10')}</span>`;
          this.isQuiet = false;
        } else if (this.noiseLevel > this.highNoiseLevel) { // Высокий уровень шума (Очень шумно)
          color = 'red';
          this.noiseMessage = `<span style="color: #E24C4B;" class="noise-message">${this.$t('page_rules_10')}</span>`;
          this.isQuiet = false;
        }

        // this.bars = this.bars.map(() => ({
        //   height: Math.max(10, normalized * 100),
        //   color: color,
        // }));

        this.bars = this.bars.map(() => ({
          height: this.getRandomNumber(normalized),
          color: color,
        }));

        this.animationFrameId = requestAnimationFrame(updateBars);
      };

      updateBars();
    },
  },
  watch: {
    currentTab(newVal) {
      if (newVal === 'final-check') {

        setTimeout(() => {
          const videoElement2 = this.$refs.videoElement2;
          if (videoElement2) {
            videoElement2.srcObject = this.streamCamera;
          } else {
            console.error('Видео элемент не найден');
          }
        }, 500)
      }
    }
  },
}
</script>

<style>

.tooltip-container {
  position: relative;
  display: inline-block;
  margin-left: 15px;
}

.triangle-icon {
  cursor: pointer;
}

.tooltip-block {
  position: absolute;
  top: 40px;
  left: -75px;
  width: 542px;
  background-color: #fff;
  padding: 25px 18px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10;
}


.tooltip-block.microphone {
  top: 40px;
  left: -220px;
}

.tooltip-block p {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #3A3A3C;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
}


</style>

<style scoped>
.loader-block {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader-face-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>